import config from "~/config";

export default function (recommendationCode: string) {
  const actions = {
    emailText: {
      subject: "Mit Energie die Welt verändern.",
      body: `Hey,\n\nmit Ökoenergie von Polarstern Energie kannst auch du die weltweite Energiewende voranbringen. Und das beste daran: Mit folgenden Code sparen wir beide jeweils ${config.recommendationValue} EUR:\n\n${recommendationCode}\n\nEinfach auf https://www.polarstern-energie.de/ gehen, Tarif auswählen und den Code bei der Bestellung eingeben.\n\nViele Grüße`,
    },
  };

  return `mailto:?subject=${encodeURIComponent(
    actions.emailText.subject,
  )}&body=${encodeURIComponent(actions.emailText.body)}`;
}
